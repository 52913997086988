import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Slider } from '@mui/material';
import { greaterThan, lessThan } from '../../../../validation';
import additionalFields from '../../../utils/additionalFields';
import TextField from './TextField';

const EcoMode = (props) => {
  const { formData, change } = props;
  const { dischargeSocLimit, morningSocLimit, chargingSocLimit } = formData || {};

  const onChangeSlider = (event, values) => {
    if (values[0] !== dischargeSocLimit) {
      change('dischargeSocLimit', values[0]);
    }
    if (values[1] !== morningSocLimit) {
      change('morningSocLimit', values[1]);
    }
    if (values[2] !== chargingSocLimit) {
      change('chargingSocLimit', values[2]);
    }
  };

  const lessThanMorningSoc = useMemo(
    () => lessThan(morningSocLimit, 'morningSocLimit.label'),
    [morningSocLimit]
  );
  const lessThanChargingSoc = useMemo(
    () => lessThan(chargingSocLimit, 'chargingSocLimit.label'),
    [chargingSocLimit]
  );
  const greaterThanDischargeSoc = useMemo(
    () => greaterThan(dischargeSocLimit, 'dischargeSocLimit.label'),
    [dischargeSocLimit]
  );
  const greaterThanMorningSoc = useMemo(
    () => greaterThan(morningSocLimit, 'morningSocLimit.label'),
    [morningSocLimit]
  );

  return (
    <>
      <Slider
        sx={{
          marginBottom: '36px',
          '& .MuiSlider-thumb': {
            color: '#1B1B1A'
          },
          '& .MuiSlider-rail': {
            background: `linear-gradient(to right, #F2D649, #F2D649 ${dischargeSocLimit}%, #C14F1A ${dischargeSocLimit}%, #C14F1A ${morningSocLimit}%, #1A60C1 ${morningSocLimit}%, #1A60C1 ${chargingSocLimit}%, #FFFFFF1A ${chargingSocLimit}%, #FFFFFF1A 100%)`,
            opacity: 1
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: 'unset',
            top: 0,
            color: 'text.primary',
            opacity: 0.5
          }
        }}
        track={false}
        aria-labelledby="track-false-range-slider"
        valueLabelDisplay="on"
        valueLabelFormat={(value) => `${value}%`}
        value={[
          dischargeSocLimit,
          morningSocLimit,
          chargingSocLimit
        ]}
        min={0}
        max={100}
        disableSwap
        onChange={onChangeSlider}
      />
      <Box className="field-wrapper">
        <TextField
          {...additionalFields.dischargeSocLimit}
          name="dischargeSocLimit"
          validate={[...additionalFields.dischargeSocLimit.validate, lessThanMorningSoc]}
        />
        <TextField
          {...additionalFields.morningSocLimit}
          name="morningSocLimit"
          validate={[
            ...additionalFields.morningSocLimit.validate,
            lessThanChargingSoc,
            greaterThanDischargeSoc
          ]}
        />
        <TextField
          {...additionalFields.chargingSocLimit}
          name="chargingSocLimit"
          validate={[...additionalFields.chargingSocLimit.validate, greaterThanMorningSoc]}
        />
      </Box>
    </>
  );
};

EcoMode.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired
};

export default EcoMode;
